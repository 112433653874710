// Core
// UI Base
import {Button, Col, Layout, message, Row, Space, Spin, Typography} from "antd";
import {COLOR_DANGER, COLOR_DARK, COLOR_INFO, COLOR_MUTED, COLOR_PRIMARY} from "App";


// Images
import iconBatteryFullCharged from "assets/svg/icon-battery-full-charged.svg";
import iconBatteryLow from "assets/svg/icon-battery-low.svg";
import iconBatteryWarning from "assets/svg/icon-battery-warning.svg";
import iconDashboardSpeed from "assets/svg/icon-dashboard-speed.svg";
import iconGeofence from "assets/svg/icon-geofence.svg";
import iconIdle from "assets/svg/icon-idle.svg";
import iconIgnition from "assets/svg/icon-ignition.svg";
import iconMaintenanceGreen from "assets/svg/icon-maintenance-green.svg";
import iconMaintenanceGrey from "assets/svg/icon-maintenance-grey.svg";
import iconMaintenancePink from "assets/svg/icon-maintenance-pink.svg";
import iconEmptyBattery from "assets/svg/icon-no-battery.svg";
import iconStopped from "assets/svg/icon-stopped.svg";
import logoBlack from "assets/svg/logoBlack.svg";

import './dashboard.scss';

// Contracts
import {EBatteryLevel} from "contracts/EBatteryLevel";
import {EDeviceStatus} from "contracts/EDeviceStatus";
import {ESubscriptionStatus} from "contracts/ESubscriptionStatus";
import {Device} from "contracts/holotrak/device";
import {IStatListItemProps} from "contracts/IStatListItemProps";
import {ITableStat} from "contracts/ITableStat";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {Helmet} from "react-helmet";
import {Link, useNavigate} from "react-router-dom";
import {selectDevicesWithSubscriptions, useGetDevicesQuery} from "redux/services/express";
import {useGetOrganisationQuery} from "redux/services/losant";
import {useGetDeviceSubscriptionsQuery} from "redux/services/losant/subscriptions";
import {useTypedSelector} from "redux/store";
import {IconEditPlanePen} from "../../assets/icons/icon-edit-plane-pen";
import {EGroupType, getRouteForGroupType} from "../../contracts/EGroupType";


// Components
const StatChart = React.lazy(() => import("components/dashboard-stats/StatChart"));
const StatDetailModal = React.lazy(() => import("components/dashboard-stats/StatDetailModal"));
const StatList = React.lazy(() => import("components/dashboard-stats/StatList"));


const {Title} = Typography;

export const Dashboard: React.FC = () => {
    // Using a query hook automatically fetches data and returns query values
    const subscriptionsQuery = {
        page: 0
    };
    const {isLoading: isLoadingDevices} = useGetDevicesQuery();
    const {isLoading: isLoadingSubscriptions} = useGetDeviceSubscriptionsQuery(subscriptionsQuery);

    const navigateTo = useNavigate();

    const unauthorizedTimeout = useRef<number>();

    const {
        isLoading: isLoadingOrganisation,
        isFetching: isFetchingOrganisation,
        isError: isErrorOrganisation,
        error: organisationError
    }
        = useGetOrganisationQuery();

    useEffect(() => {
        if (isLoadingOrganisation && isFetchingOrganisation) return;

        const errorMessage = "Unauthorized";
        if (isErrorOrganisation) {
            if (unauthorizedTimeout.current) {
                clearTimeout(unauthorizedTimeout.current);
            }

            unauthorizedTimeout.current = window.setTimeout(() => {
                message.error(errorMessage).then(() => {
                    navigateTo("/logout");
                });
            }, 1000);
        }
    }, [isErrorOrganisation, isFetchingOrganisation, isLoadingOrganisation, navigateTo, organisationError]);


    const devices: Device[] = useTypedSelector(state => selectDevicesWithSubscriptions(state, subscriptionsQuery));

    const [deviceStatOnDisplay, setDeviceStatOnDisplay] = useState<ITableStat>({
        key: "",
        details: {
            key: "",
            title: "",
            stat: [],
            background: "",
        }
    });
    const [deviceListShown, setDeviceListShown] = useState(false);

    const showDevicesForStatItem = (key: string, chosenStat: IStatListItemProps) => {
        setDeviceStatOnDisplay({
            key,
            details: chosenStat
        });
        setDeviceListShown(true);
    };

    const {
        online,
        offline,
        gpsNotConnected,
        notConnected,
        stoppedReporting,
        idle,
        fullyCharged,
        batteryWarning,
        batteryLow,
        batteryNotConnected,
        speedAlert,
        geofenceAlert,
        ignitionAlert,
        activeDevices,
        inActiveDevices,
        deactivated,
        retired,
    } = useMemo(() => {
        return {
            online: devices.filter(device => device.status === EDeviceStatus.ONLINE),
            offline: devices.filter(device => device.status === EDeviceStatus.OFFLINE),
            gpsNotConnected: devices.filter(device => device.status === EDeviceStatus.GPS_NOT_CONNECTED),
            notConnected: devices.filter(device => device.status === EDeviceStatus.NEVER_CONNECTED),
            stoppedReporting: devices.filter(device => device.status === EDeviceStatus.STOPPED_REPORTING),
            idle: devices.filter(device => device.status === EDeviceStatus.IDLE),
            fullyCharged: devices.filter(device => device.batteryLevel === EBatteryLevel.FULL),
            batteryWarning: devices.filter(device => device.batteryLevel === EBatteryLevel.WARNING),
            batteryLow: devices.filter(device => device.batteryLevel === EBatteryLevel.LOW),
            batteryNotConnected: devices.filter(device => device.batteryLevel === EBatteryLevel.NOT_CONNECTED),
            speedAlert: [],
            geofenceAlert: [],
            ignitionAlert: [],
            activeDevices: devices.filter(device => device.subscriptionStatus === ESubscriptionStatus.ACTIVE),
            inActiveDevices: devices.filter(device => device.subscriptionStatus === ESubscriptionStatus.INACTIVE),
            deactivated: devices.filter(device => device.subscriptionStatus === ESubscriptionStatus.DEACTIVATED),
            retired: devices.filter(device => device.subscriptionStatus === ESubscriptionStatus.RETIRED)
        }
    }, [devices]);

    const deviceStatusStats = [
        {
            key: "online",
            title: "Online",
            stat: online,
            background: COLOR_PRIMARY
        },
        {
            key: "offline",
            title: "Offline",
            stat: offline,
            background: COLOR_DANGER
        },
        {
            key: "gps-not-connected",
            title: "GPS Not Connected",
            stat: gpsNotConnected,
            background: COLOR_INFO
        },
        {
            key: "not-connected",
            title: "Not Connected",
            stat: notConnected,
            background: COLOR_MUTED
        },
        {
            key: "stopped-reporting",
            title: "Stopped Reporting",
            stat: stoppedReporting,
            background: COLOR_DARK
        }
    ];
    const deviceMovementStats = [
        {
            key: "moving",
            title: "Moving",
            stat: online,
            background: COLOR_PRIMARY
        },
        {
            key: "stopped",
            title: "Stopped",
            stat: offline,
            background: null,
            icon: iconStopped
        },
        {
            key: "idle",
            title: "Idle",
            stat: idle,
            background: null,
            icon: iconIdle
        }
    ];
    const deviceBatteryStats = [
        {
            key: "full",
            title: "Fully Charged",
            stat: fullyCharged,
            background: null,
            icon: iconBatteryFullCharged
        },
        {
            key: "low",
            title: "Low",
            stat: batteryLow,
            background: null,
            icon: iconBatteryLow
        },
        {
            key: "warning",
            title: "Warning",
            stat: batteryWarning,
            background: null,
            icon: iconBatteryWarning
        },
        {
            key: "not-connected",
            title: "Not Connected",
            stat: batteryNotConnected,
            background: null,
            icon: iconEmptyBattery
        }
    ];
    const deviceAlertStats = [
        {
            key: "speed",
            title: "Speed",
            stat: speedAlert,
            background: null,
            icon: iconDashboardSpeed
        },
        {
            key: "geofence",
            title: "Geofence",
            stat: geofenceAlert,
            background: null,
            icon: iconGeofence
        },
        {
            key: "ignition",
            title: "Ignition",
            stat: ignitionAlert,
            background: null,
            icon: iconIgnition
        }
    ];
    const deviceActivationStats = [
        {
            key: "active",
            title: "Active",
            stat: activeDevices,
            background: COLOR_PRIMARY,
        },
        {
            key: "inactive",
            title: "In Active",
            stat: inActiveDevices,
            background: COLOR_DANGER,
        },
        {
            key: 'deactivated',
            title: 'Deactivated',
            stat: deactivated,
            background: COLOR_INFO,
        },
        {
            key: 'retired',
            title: 'Retired',
            stat: retired,
            background: COLOR_MUTED,
        }
    ]
    // FUTURE: calculated device stats for maintenance as well.
    const deviceMaintenanceStats = [
        {
            key: "completed",
            title: "Completed",
            stat: [],
            background: null,
            icon: iconMaintenanceGreen
        },
        {
            key: "scheduled",
            title: "Scheduled",
            stat: [],
            background: null,
            icon: iconMaintenanceGreen
        },
        {
            key: "expired",
            title: "Expired",
            stat: [],
            background: null,
            icon: iconMaintenancePink
        },
        {
            key: "other",
            title: "Other",
            stat: [],
            background: null,
            icon: iconMaintenanceGrey
        }
    ];

    return (
        <React.Suspense fallback={<Spin spinning={true}/>}>
            <Helmet>
                <title>Dashboard</title>
                <meta name='description' content='Holotrak Dashboard'/>
            </Helmet>

            <Layout className="dashboard-container">
                <Layout.Content>
                    <Row>
                        <Col span={24}>
                            <Spin spinning={isLoadingDevices || isLoadingSubscriptions}>
                                <Row className="dashboard-header">
                                    <Col className="dashboard-tile dashboard-header-tile dashboard-header-left">
                                        <Title level={5}>Assets</Title>
                                        <Link to="/vehicles" className="grey-btn">
                                            <Button className='button-link'>
                                                View All Vehicles
                                            </Button>
                                        </Link>
                                    </Col>
                                    <Col className="dashboard-tile dashboard-header-tile dashboard-header-right">
                                        <Title level={5}>Inventory</Title>
                                        <Link to="/devices" className="grey-btn">
                                            <Button className='button-link'>
                                                View All Devices
                                            </Button>
                                        </Link>
                                    </Col>
                                </Row>

                                <Row className="dashboard-top">
                                    <Col className="dashboard-tile dashboard-top-grid" span={8}>
                                        <div className="dashboard-tile-content">
                                            <StatList
                                                statItems={deviceStatusStats}
                                                title={"Status"}
                                                onClick={showDevicesForStatItem}
                                            />
                                        </div>
                                    </Col>
                                    <Col className="dashboard-tile dashboard-top-grid" span={8}>
                                        <div className="dashboard-tile-content">
                                            <StatList
                                                statItems={deviceMovementStats}
                                                title={"Movement"}
                                                onClick={showDevicesForStatItem}
                                            />
                                        </div>
                                    </Col>
                                    <Col className="dashboard-tile dashboard-tile-with-chart dashboard-top-grid2"
                                         span={8}>
                                        <div className='dashboard-tile-content'>
                                            <StatChart
                                                statItems={deviceActivationStats}
                                                title={"Activation"}
                                                onClick={showDevicesForStatItem}
                                            />
                                        </div>
                                    </Col>
                                </Row>

                                <Row className="dashboard-bottom">
                                    <Col className="dashboard-tile dashboard-bottom-grid" span={6}>
                                        <div className="dashboard-tile-header bottom-grid-header">
                                            <Title level={5}>Battery</Title>
                                        </div>
                                        <div className="dashboard-tile-content">
                                            <StatList
                                                statItems={deviceBatteryStats}
                                                title={"Battery"}
                                                onClick={showDevicesForStatItem}
                                            />
                                        </div>
                                    </Col>
                                    <Col className="dashboard-tile dashboard-bottom-grid" span={6}>
                                        <div className="dashboard-tile-header bottom-grid-header">
                                            <Title level={5}>Alert Notifications</Title>
                                            <Link to={'/notifications'} className='icon-link'>
                                                <Button
                                                    ghost
                                                    icon={<IconEditPlanePen/>}
                                                />
                                            </Link>
                                        </div>
                                        <div className="dashboard-tile-content">
                                            <StatList statItems={deviceAlertStats} title={"Alerts"}/>
                                        </div>
                                    </Col>
                                    <Col className="dashboard-tile dashboard-bottom-grid" span={6}>
                                        <div className="dashboard-tile-header bottom-grid-header">
                                            <Title level={5}>Maintenance</Title>
                                        </div>
                                        <div className="dashboard-tile-content">
                                            <StatList statItems={deviceMaintenanceStats} title={"Maintenance"}/>
                                        </div>
                                    </Col>
                                    <Col className="dashboard-tile dashboard-bottom-grid" span={6}>
                                        <div className="dashboard-tile-header bottom-grid-header">
                                            <Title level={5}>Quick Links</Title>
                                            <Link to={'/settings/general'} className='icon-link'>
                                                <Button
                                                    ghost
                                                    icon={<IconEditPlanePen/>}
                                                />
                                            </Link>
                                        </div>
                                        <div className="dashboard-tile-content">
                                            <Space direction='vertical'>
                                                <Link to={getRouteForGroupType(EGroupType.DEVICE)} className="grey-btn">
                                                    <Button className='button-link'>
                                                        View All Devices
                                                    </Button>
                                                </Link>
                                                <Link to={getRouteForGroupType(EGroupType.VEHICLE)}
                                                      className="grey-btn">
                                                    <Button className='button-link'>
                                                        View All Vehicles
                                                    </Button>
                                                </Link>
                                                <Link to="/help" className="grey-btn">
                                                    <Button className='button-link'>
                                                        Support
                                                    </Button>
                                                </Link>
                                                <Link to="/messages" className="grey-btn">
                                                    <Button className='button-link'>
                                                        My Messages
                                                    </Button>
                                                </Link>
                                            </Space>
                                        </div>
                                    </Col>
                                </Row>

                            </Spin>
                        </Col>
                    </Row>
                </Layout.Content>
                <Layout.Footer className="bottom-logo">
                    <img src={logoBlack} alt=""/>
                </Layout.Footer>
            </Layout>

            <StatDetailModal
                stat={deviceStatOnDisplay}
                open={deviceListShown}
                onCancel={() => setDeviceListShown(false)}
            />
        </React.Suspense>
    );
};
